import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

import OthelloCarrusel from "../../componentsothello/OthelloCarrusel.js";

import { TipoCarrusel } from "../../othelloConstantes.jsx";
import { GetInfoAdicionalHeaderCorporativa } from "../../othelloFunciones.jsx";

import { useTranslation } from 'react-i18next';


function IndexHeader({ ...props }) {

    //variables de las propiedas recibidas
    const { oficina } = props;

    const { t } = useTranslation();

    const [items, setItems] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetImagenesPortadaOficina')
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setLoading(false)
            }).catch(error => {
                setItems(null);
                setLoading(false)
            });
    }, [loading]);


    let imgback = oficina != null ? "imagenes/fotoreserva_" + oficina.id_Oficina + "_.jpg" : "";

  return (
    <>
          <div className="header-3">              
              <div className="page-carousel">
                  <div className="filter" />                  
                  {!loading && items != null && items.length > 0 ? <OthelloCarrusel tipo={TipoCarrusel.Portada} carouselItems={items} />  : ""}
              </div>
          </div>
          {
              //Si estamos trabajando con los apartamentos
              !loading && items != null && items.length > 0 ? GetInfoAdicionalHeaderCorporativa(items[0], t) : ""                                            
          }
          {
              !loading && oficina != null && oficina.urlWebReservas != null && oficina.urlWebReservas != "" ?

                  <div className="header-3" style={{ backgroundColor: '#333333', backgroundImage: "url(" + (imgback) + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: 200 }}>
                      <Container>
                          <Row>
                              {/* HE COMENTADO UNA PARTE PARA PONERLO SENCILLO*/}
                              {/*<Col className="ml-auto mr-auto" xs="6" sm="7" md="8" lg="9">*/}
                              {/*        <h5 className="title text-center" style={{ color: '#ffffff', marginTop: 85  }} ><b>{t != null ? t("_reservafacil") : ""}</b></h5>*/}
                              {/*</Col>*/}
                              {/*<Col className="ml-auto mr-auto" xs="6" sm="5" md="4" lg="3">*/}
                              <Col>
                                  <center>
                                      <div style={{ marginTop: 75 }}>
                                          <Button
                                              color="info"
                                              href={"https://"+oficina.urlWebReservas}
                                              size="lg"
                                              target="_blank">
                                              <i class="fa fa-ticket" aria-hidden="true"></i> {t("_reservaaqui")}
                                          </Button>
                                      </div>
                                  </center>
                              </Col>
                          </Row>
                      </Container>
                  </div> : ""
          }
    </>
  );
}

export default IndexHeader;