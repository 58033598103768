import React from "react";

// reactstrap components
import { Button, Form, Input, Container, Row, Col } from "reactstrap";

import { TipoOficina } from "../othelloConstantes.jsx";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";

import { GetGoogleMaps } from "../othelloFunciones.jsx";

import { useTranslation } from 'react-i18next';

function ContactUs() {

    const { t, i18n } = useTranslation();

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });
    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [oficina, setOficina] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetOficinaVirtual')
            .then(response => response.json())
            .then(data => {
                setOficina(data);
                setLoading(false)
            }).catch(error => {
                setOficina(null);
                setLoading(false)
            });

    }, [loading]);

    function GuardarContacto(event) {
        event.preventDefault();

        let formData = new FormData(event.target);

        fetch('api/Data/GuardarContacto', {
            method: 'POST',
            body: formData,

        }).then(response => response.json()).then(res => {
            //Si hubo error
            if (!res) {
                setSaveError(true);
                setSaveOk(false);
            }
            else {
                setSaveError(false);
                setSaveOk(true);
            }
        }).catch(error => {
            setSaveError(true);
            setSaveOk(false);
        });
    }

    const [saveerror, setSaveError] = React.useState(false); 
    const [saveOk, setSaveOk] = React.useState(false); 

    let latitud = !loading && oficina != null ? oficina.lat : 0;
    let longitud = !loading && oficina != null ? oficina.lon : 0;

  return (
    <>
      <ColorNavbarATMSimple color='danger' />
      <div className="main">
        <div className="section section-white">
            <div className="big-map" id="contactUsMap">                      
                      {GetGoogleMaps(latitud, longitud)}                                      
            </div>
          <Container>                      
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                        <h2 className="title"><b>{t("_formcontacto")}</b></h2> 
                    <br />                              
                <Form className="contact" onSubmit={(e) => { e.preventDefault(); GuardarContacto(e) }}>
                  <Row>
                    <Col md="12">
                            <Input placeholder={t("_nombre")} type="text" id="nombre" name="nombre"/>
                    </Col>                  
                  </Row>
                  <Row>
                    <Col md="12">
                              <Input placeholder={t("_email")} type="text" id="eMail" name="eMail"/>
                    </Col>
                    <Col md="6">
                            <Input placeholder={t("_telefono")} type="text" id="telefono" name="telefono"/>
                    </Col>
                  </Row>
                          <Input placeholder={t("_mensaje")} rows="7" type="textarea" id="mensaje" name="mensaje"/>                     
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Button block className="btn-round" color="primary">
                        {t("_enviar")}
                      </Button>
                    </Col>
                    </Row>
                    <br/>
                                  {saveerror ? <center><span className='text-danger'><b>{t("_notdooperacion")}</b></span></center> : ""}
                                  {saveOk ? <center><span className='text-danger'><b>{t("_registradapeticion")}</b></span></center> : ""}                                  
                </Form>               
                </Col>                        
                <Col className="ml-auto mr-auto" md="6">
                              <h2 className="title"><b>{t("_contactaconnosotros")}</b></h2>
                    <p>
                                  <b>{t("_nuestrodatos")} </b>
                              </p>
                              <hr />   
                              <p>
                                  {!loading && oficina != null && oficina.descripcion != null && oficina.descripcion != "" ? oficina.descripcion.split("\n").map((i, key) => { return <div key={key}><b>{i}</b></div>; }) : ""}                                 
                                  </p>
                </Col>
            </Row>
          </Container>
        </div>
      </div>
          {!loading && oficina != null ? (oficina.tipoOfi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />) : ""}          
    </>
  );
}

export default ContactUs;
